import React from 'react';
import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import SEO from 'components/Seo';
import { graphql } from 'gatsby';
import { getTranslates } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';

export default ({ data }) => {
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <div className="mt-30 mb-30">
      <SEO title={getTranslates(data.allStrapiClpPrivacyPolicyPage, 'header', language)} />

      <Container>
        <Typography.Title level={2} className="clp-typography-title clp-typography-title_size_lg mb-30">
          {getTranslates(data.allStrapiClpPrivacyPolicyPage, 'header', language)}
        </Typography.Title>

        <Typography.Text className="clp-typography-text clp-typography-text_size_lg mb-50">
          {getTranslates(data.allStrapiClpPrivacyPolicyPage, 'content', language)}
        </Typography.Text>
      </Container>
    </div>
  );
};

export const query = graphql`
  query {
    allStrapiClpPrivacyPolicyPage {
      edges {
        node {
          header__en
          header__fr
          content__en
          content__fr
        }
      }
    }
  }
`;
